<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCommon/communtiy" class="main_left" :expandAll="true" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      classifications: [],
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "contractName",
            title: "合同名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入合同名称",
              },
            },
          },
          {
            field: "contractNum",
            title: "合同编号",
            minWidth: 160,
            editRender: {
              name: "$select",
              props: {
                placeholder: "请输入合同编号",
              },
            },
          },
          {
            field: "contractAmount",
            title: "合同金额",
            minWidth: 160,
            editRender: {
              name: "$select",
              props: {
                placeholder: "请输入合同金额",
              },
            },
          },
          {
            field: "partyAName",
            title: "甲方名称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请选择甲方名称",
              },
            },
          },
          {
            field: "partyaPerson",
            title: "甲方联系人",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入甲方联系人",
              },
            },
          },
          {
            field: "partyaPhone",
            title: "甲方联系电话",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请输入甲方联系电话",
              },
            },
          },
          {
            field: "partybPerson",
            title: "乙方联系人",
            minWidth: 160,
            editRender: {
              name: "$VxeSelectContractOwner",
              props: {
                placeholder: "请输入乙方联系人",
              },
            },
          },
          {
            field: "partybPhone",
            title: "乙方联系电话",
            minWidth: 160,
            editRender: {
              name: "$VxeSelectContractOwner",
              props: {
                placeholder: "请输入乙方联系电话",
              },
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 1000,
        height: 800,
        titleWidth: 100,
        items: [{
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "基本信息",
                position: "left"
              }
            },
          },
          {
            field: "parentId",
            title: "所属小区",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属小区",
              },
              events: {
                click: (params, node) => {
                  this.currNode = node;
                  let { data } = params;
                  if (node.type == "1001") {
                    data["parentId"] = node.id;
                    data["noticeType"] = "";
                  } else {
                    this.$message.error("请选择所属小区");
                  }
                },
              },
            },
          },
          {
            field: "contractName",
            title: "合同名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入合同名称",
              },
            },
          },
          {
            field: "contractNum",
            title: "合同编号",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入合同编号",
              },
            },
          },
          {
            field: "contractAmount",
            title: "合同金额",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "number",
                maxlength: 9,
                placeholder: "请输入合同金额",
              },
            },
          },
          {
            field: "handledBy",
            title: "经办人",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入经办人",
              },
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "甲方信息",
                position: "left"
              }
            },
          },
          {
            field: "partyAName",
            title: "甲方名称",
            span: 12,
            itemRender: {
              name: "$VxeSelectContractOwner",
              props: {
                maxlength: 24,
                placeholder: "请选择甲方名称",
              },
              events: {
                change: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.partyAName;
                  data["partyAId"] = node.id;
                  data["partyaPerson"] = node.name;
                  data["partyaPhone"] = node.phone;
                },
              },
            },
          },
          {
            field: "partyaPerson",
            title: "甲方联系人",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入甲方联系人",
              },
            },
          },
          {
            field: "partyaPhone",
            title: "甲方电话",
            span: 12,
            itemRender: {
              name: "$VxePhoneInput",
              props: {
                required: true,
                placeholder: "请输入甲方联系人电话",
              },
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "乙方信息",
                position: "left"
              }
            },
          },
          {
            field: "partybPerson",
            title: "乙方联系人",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入乙方联系人",
              },
            },
          },

          {
            field: "partybPhone",
            title: "乙方电话",
            span: 12,
            itemRender: {
              name: "$VxePhoneInput",
              props: {
                required: true,
                placeholder: "请输入乙方联系电话",
              },
            },
          },
          {
            field: "remark",
            title: "内容",
            span: 24,
            itemRender: {
              name: "$VxeEdit",
              props: {
                maxlength: 24,
                placeholder: "请输入通知内容",
              },
            },
          },
          {
            span: 24,
            itemRender: {
              name: "$VxeDivider",
              props: {
                title: "附件信息",
                position: "left"
              }
            },
          },
          {
            field: "contractAnnex",
            title: "附件",
            span: 24,
            itemRender: {
              name: "$VxeAttachmentTable",
              props: {
                placeholder: "请上传附件",
              }
            },
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属小区" }],
          contractName: [{ required: true, message: "请输入合同名称" }],
          contractNum: [{ required: true, message: "请输入合同编号" }],
          contractAmount: [{ required: true, message: "请输入合同金额" }],
          handledBy: [{ required: true, message: "请输入经办人" }],
          partyAName: [{ required: true, message: "请选择甲方名称" }],
          partyaPerson: [{ required: true, message: "请输入甲方联系人" }],
          partyaPhone: [{ required: true, message: "请输入甲方联系人电话" }],
          partybPerson: [{ required: true, message: "请输入乙方联系人" }],
          partybPhone: [{ required: true, message: "请输入乙方联系电话" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          partyAName: "",
          partyaPerson: "",
          partyaPhone: "",
          enabled: "1",
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "contractName",
            title: "合同名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入合同名称",
              },
            },
          },
          // {
          //   field: "enabled",
          //   title: "状态",
          //   itemRender: {
          //     name: "$select",
          //     optionTypeCode: "enabledStatus",
          //     props: {
          //       placeholder: "请选择状态",
          //     },
          //   },
          // },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          contractName: "",
        },
      },
    };
  },
  methods: {
    ...mapActions(["findByEventService"]),
    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.gridOptions.columns, treeData, "parentId");
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data["parentId"] = node.id;
      this.searchOptions.data["parentId"] = node.id;
      this.$refs.xGrid.getLists();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
